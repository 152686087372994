define("discourse/plugins/discourse-journal/discourse/templates/connectors/topic-category/journal-tip-container", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if topic.showJournalTip}}
    {{journal-topic-tip
      label="topic.tip.journal.title"
      details="topic.tip.journal.details"}}
  {{/if}}
  */
  {
    "id": "qh1kJZTB",
    "block": "[[[41,[30,0,[\"topic\",\"showJournalTip\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"label\",\"details\"],[\"topic.tip.journal.title\",\"topic.tip.journal.details\"]]]],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `topic` property path was used in the `discourse/plugins/discourse-journal/discourse/templates/connectors/topic-category/journal-tip-container.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.topic}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"journal-topic-tip\"]]",
    "moduleName": "discourse/plugins/discourse-journal/discourse/templates/connectors/topic-category/journal-tip-container.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});