define("discourse/plugins/discourse-journal/discourse/initializers/journal-composer", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "discourse/models/composer", "@ember/object", "@ember/object/computed"], function (_exports, _pluginApi, _decorators, _composer, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    name: "journal-composer",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (!siteSettings.journal_enabled) return;
      function getJournalComposerKey(action, composerModel) {
        let key;
        let post = composerModel.post;
        if (action === _composer.CREATE_TOPIC) {
          key = "create_journal";
        } else if (action === _composer.REPLY && post) {
          key = post.comment ? "reply_to_comment" : "create_comment";
        } else if (action === _composer.EDIT && post) {
          key = post.comment ? "edit_comment" : "edit_entry";
        } else {
          key = "create_entry";
        }
        return key;
      }
      function getJournalComposerText(type) {
        let icon = "reply";
        if (type === "create_comment") {
          icon = "comment";
        } else if (type === "create_journal") {
          icon = "plus";
        } else if (["edit_entry", "edit_comment"].includes(type)) {
          icon = "pencil-alt";
        }
        return {
          icon,
          name: `composer.composer_actions.${type}.name`,
          description: `composer.composer_actions.${type}.description`
        };
      }
      (0, _pluginApi.withPluginApi)("0.8.12", api => {
        var _dec, _dec2, _dec3, _dec4, _obj, _dec5, _obj2;
        api.modifyClass('controller:composer', (_dec = (0, _decorators.default)('model.category'), _dec2 = (0, _decorators.default)('model.action', "model.post"), _dec3 = (0, _decorators.default)("model.action", "isWhispering", "model.editConflict", "isJournal", "journalComposerText.name"), _dec4 = (0, _decorators.default)("model.action", "isWhispering", "isJournal", "journalComposerText.icon"), (_obj = {
          open(opts) {
            if (opts.topic && opts.topic.journal && opts.quote && !opts.post) {
              opts.post = opts.topic.postStream.posts[0];
            }
            return this._super(opts);
          },
          isJournal(category) {
            return category && category.journal;
          },
          journalComposerText(action, post) {
            let key = getJournalComposerKey(action, this.model);
            return getJournalComposerText(key);
          },
          saveLabel(modelAction, isWhispering, editConflict, isJournal, journalLabel) {
            if (isJournal) {
              return journalLabel;
            } else {
              return this._super(...arguments);
            }
          },
          saveIcon(modelAction, isWhispering, isJournal, journalIcon) {
            if (isJournal) {
              return journalIcon;
            } else {
              return this._super(...arguments);
            }
          }
        }, (_applyDecoratedDescriptor(_obj, "isJournal", [_dec], Object.getOwnPropertyDescriptor(_obj, "isJournal"), _obj), _applyDecoratedDescriptor(_obj, "journalComposerText", [_dec2], Object.getOwnPropertyDescriptor(_obj, "journalComposerText"), _obj), _applyDecoratedDescriptor(_obj, "saveLabel", [_dec3], Object.getOwnPropertyDescriptor(_obj, "saveLabel"), _obj), _applyDecoratedDescriptor(_obj, "saveIcon", [_dec4], Object.getOwnPropertyDescriptor(_obj, "saveIcon"), _obj)), _obj)));
        api.modifyClass("component:composer-action-title", (_dec5 = (0, _decorators.default)("options", "action", "model.category"), (_obj2 = {
          actionTitle(opts, action, category) {
            let key = getJournalComposerKey(action, this.model);
            let text = getJournalComposerText(key);
            if (category && category.journal && text) {
              return I18n.t(text.name);
            } else {
              return this._super(...arguments);
            }
          }
        }, (_applyDecoratedDescriptor(_obj2, "actionTitle", [_dec5], Object.getOwnPropertyDescriptor(_obj2, "actionTitle"), _obj2)), _obj2)));
        api.modifyClass("component:composer-actions", {
          didReceiveAttrs() {
            const composer = this.get("composerModel");
            if (composer) {
              this.set("postSnapshot", composer.post);
            }
            this._super(...arguments);
          },
          commenting: (0, _computed.alias)("postSnapshot.journal"),
          commentKey: (0, _object.computed)("commenting", function () {
            return getJournalComposerKey(this.action, this.composerModel);
          }),
          iconForComposerAction: (0, _object.computed)("action", "commenting", function () {
            if (this.commenting) {
              return getJournalComposerText(this.commentKey).icon;
            } else {
              return this._super(...arguments);
            }
          }),
          content: (0, _object.computed)("seq", "commenting", function () {
            if (this.commenting) {
              const text = getJournalComposerText(this.commentKey);
              return [{
                id: "reply_to_post",
                icon: text.icon,
                name: I18n.t(text.name),
                description: I18n.t(text.description)
              }];
            } else {
              return this._super(...arguments);
            }
          })
        });
      });
    }
  };
});