define("discourse/plugins/discourse-journal/discourse/connectors/category-custom-settings/enable-journal", ["exports", "discourse/lib/ajax"], function (_exports, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    setupComponent(attrs, component) {
      const category = attrs.category;
      if (!category.custom_fields) {
        category.custom_fields = {};
      }
      if (!category.custom_fields.journal_author_groups) {
        category.custom_fields.journal_author_groups = "";
      }
      const site = component.get("site");
      const siteGroups = this.site.groups;
      const authorGroups = category.custom_fields.journal_author_groups.split("|").filter(a => a.length != "");
      this.setProperties({
        authorGroups,
        siteGroups,
        categoryId: category.id
      });
    },
    actions: {
      onSelectAuthorGroup(authorGroups) {
        this.setProperties({
          authorGroups,
          "category.custom_fields.journal_author_groups": authorGroups.join('|')
        });
      },
      updateSortOrder() {
        this.set('updatingSortOrder', true);
        (0, _ajax.ajax)('/journal//update-sort-order', {
          type: "POST",
          data: {
            category_id: this.categoryId
          }
        }).then(result => {
          let syncResultIcon = result.success ? "check" : "times";
          this.setProperties({
            updatingSortOrder: false,
            syncResultIcon
          });
        }).catch(() => {
          this.setProperties({
            syncResultIcon: 'times',
            updatingSortOrder: false
          });
        }).finally(() => {
          setTimeout(() => {
            this.set('syncResultIcon', null);
          }, 6000);
        });
      }
    }
  };
});