define("discourse/plugins/discourse-journal/discourse/initializers/journal-discovery", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators"], function (_exports, _pluginApi, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    name: "journal-discovery",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (!siteSettings.journal_enabled) return;
      (0, _pluginApi.withPluginApi)('0.8.12', api => {
        var _dec, _obj;
        api.modifyClass('component:d-navigation', (_dec = (0, _decorators.default)("hasDraft", "category.journal"), (_obj = {
          createTopicLabel(hasDraft, journalCategory) {
            if (journalCategory) {
              return "topic.create_journal.label";
            } else {
              return this._super(...arguments);
            }
          }
        }, (_applyDecoratedDescriptor(_obj, "createTopicLabel", [_dec], Object.getOwnPropertyDescriptor(_obj, "createTopicLabel"), _obj)), _obj)));
        api.modifyClass('route:discovery', {
          discoveryCategory() {
            return this.controllerFor("navigation/category").get("category");
          },
          actions: {
            didTransition() {
              const category = this.discoveryCategory();
              if (category && category.journal) {
                $("body").addClass("journal-category");
              }
              return this._super();
            },
            willTransition() {
              const category = this.discoveryCategory();
              if (category && category.journal) {
                $("body").removeClass("journal-category");
              }
              return this._super();
            }
          }
        });
      });
    }
  };
});