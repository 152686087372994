define("discourse/plugins/discourse-journal/discourse/templates/connectors/after-topic-footer-main-buttons/entry-button-wrapper", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if topic.can_create_entry}}
    {{d-button
      class="btn-primary create entry"
      icon="reply"
      action="createEntry"
      label="topic.entry.title"
      title="topic.entry.title"
    }}
  {{/if}}
  
  */
  {
    "id": "KlX/7V1p",
    "block": "[[[41,[30,0,[\"topic\",\"can_create_entry\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"class\",\"icon\",\"action\",\"label\",\"title\"],[\"btn-primary create entry\",\"reply\",\"createEntry\",\"topic.entry.title\",\"topic.entry.title\"]]]],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `topic` property path was used in the `discourse/plugins/discourse-journal/discourse/templates/connectors/after-topic-footer-main-buttons/entry-button-wrapper.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.topic}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-journal/discourse/templates/connectors/after-topic-footer-main-buttons/entry-button-wrapper.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});