define("discourse/plugins/discourse-journal/discourse/components/journal-topic-tip", ["exports", "discourse/lib/text", "@ember/component", "@ember/runloop"], function (_exports, _text, _component, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ["journal-topic-tip"],
    didInsertElement() {
      this._super(...arguments);
      $(document).on("click", (0, _runloop.bind)(this, this.documentClick));
      const rawDetails = I18n.t(this.details);
      if (rawDetails) {
        (0, _text.cookAsync)(rawDetails).then(cooked => {
          this.set("cookedDetails", cooked);
        });
      }
    },
    willDestroyElement() {
      $(document).off("click", (0, _runloop.bind)(this, this.documentClick));
    },
    documentClick(e) {
      const $element = $(this.element);
      const $target = $(e.target);
      if ($target.closest($element).length < 1 && this._state !== "destroying") {
        this.set("showDetails", false);
      }
    },
    actions: {
      toggleDetails() {
        this.toggleProperty("showDetails");
      }
    }
  });
});