define("discourse/plugins/discourse-journal/discourse/initializers/journal-topic", ["exports", "discourse-common/utils/decorators", "discourse/lib/keyboard-shortcuts", "discourse/widgets/post", "discourse/helpers/node", "discourse/lib/plugin-api", "@ember/runloop", "virtual-dom", "discourse-common/lib/object"], function (_exports, _decorators, _keyboardShortcuts, _post, _node, _pluginApi, _runloop, _virtualDom, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    name: "journal-topic",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (!siteSettings.journal_enabled) {
        return;
      }
      (0, _pluginApi.withPluginApi)("0.8.12", api => {
        var _dec, _dec2, _obj, _dec3, _obj2;
        api.modifyClass('route:topic', {
          isJournal() {
            const controller = this.controllerFor("topic");
            const topic = controller.get("model");
            return topic.journal;
          },
          actions: {
            didTransition() {
              if (this.isJournal()) {
                _keyboardShortcuts.default.pause(["c"]);
                $("body").addClass("topic-journal");
              }
              return this._super(...arguments);
            },
            willTransition() {
              if (this.isJournal()) {
                _keyboardShortcuts.default.unpause(["c"]);
                $("body").removeClass("topic-journal");
              }
              return this._super(...arguments);
            }
          }
        });
        api.modifyClass("model:topic", (_dec = (0, _decorators.default)("journal"), _dec2 = (0, _decorators.default)("highest_post_number", "url", "last_entry_post_number"), (_obj = {
          showJournalTip(journalEnabled) {
            return journalEnabled && siteSettings.journal_show_topic_tip;
          },
          lastPostUrl(highestPostNumber, url, lastEntryPostNumber) {
            return lastEntryPostNumber ? this.urlForPostNumber(lastEntryPostNumber) : this.urlForPostNumber(highestPostNumber);
          }
        }, (_applyDecoratedDescriptor(_obj, "showJournalTip", [_dec], Object.getOwnPropertyDescriptor(_obj, "showJournalTip"), _obj), _applyDecoratedDescriptor(_obj, "lastPostUrl", [_dec2], Object.getOwnPropertyDescriptor(_obj, "lastPostUrl"), _obj)), _obj)));
        api.modifyClass("component:topic-footer-buttons", {
          didInsertElement() {
            this._super(...arguments);
            const journalEnabled = this.get("topic.journal");
            if (journalEnabled) {
              (0, _runloop.scheduleOnce)("afterRender", () => {
                $(".topic-footer-main-buttons > button.create", this.element).hide();
              });
            }
          }
        });
        api.reopenWidget("timeline-scrollarea", {
          html(attrs, state) {
            const result = this._super(attrs, state);
            if (siteSettings.journal_entries_timeline && attrs.topic.journal) {
              const position = this.position();
              result.push(this.attach("timeline-entries", (0, _object.deepMerge)(position, attrs)));
            }
            return result;
          }
        });
        api.reopenWidget("timeline-last-read", {
          html(attrs) {
            if (attrs.journal) {
              return '';
            } else {
              return this._super(...arguments);
            }
          }
        });
        api.modifyClass("component:topic-progress", (_dec3 = (0, _decorators.default)("progressPosition", "topic.last_read_post_id", "topic.journal"), (_obj2 = {
          showBackButton(position, lastReadId, journalEnabled) {
            if (journalEnabled) {
              return false;
            } else {
              return this._super(...arguments);
            }
          }
        }, (_applyDecoratedDescriptor(_obj2, "showBackButton", [_dec3], Object.getOwnPropertyDescriptor(_obj2, "showBackButton"), _obj2)), _obj2)));
        function renderParticipants(userFilters, participants) {
          if (!participants) {
            return;
          }
          userFilters = userFilters || [];
          return participants.map(p => {
            return this.attach("topic-participant", p, {
              state: {
                toggled: userFilters.includes(p.username)
              }
            });
          });
        }
        api.reopenWidget("topic-map-summary", {
          html(attrs, state) {
            if (attrs.journal) {
              return this.journalMap(attrs, state);
            } else {
              return this._super(attrs, state);
            }
          },
          journalMap(attrs, state) {
            const contents = [];
            const post = this.findAncestorModel();
            const topic = post.topic;
            contents.push((0, _virtualDom.h)("li", [(0, _virtualDom.h)("h4", I18n.t("created_lowercase")), (0, _virtualDom.h)("div.topic-map-post.created-at", [(0, _post.avatarFor)("tiny", {
              username: attrs.createdByUsername,
              template: attrs.createdByAvatarTemplate,
              name: attrs.createdByName
            }), (0, _node.dateNode)(attrs.topicCreatedAt)])]));
            let lastEntryUrl = attrs.topicUrl + "/" + topic.last_entry_post_number;
            contents.push((0, _virtualDom.h)("li", (0, _virtualDom.h)("a", {
              attributes: {
                href: lastEntryUrl
              }
            }, [(0, _virtualDom.h)("h4", I18n.t(`last_entry_lowercase`)), (0, _virtualDom.h)("div.topic-map-post.last-entry", [(0, _post.avatarFor)("tiny", {
              username: topic.journal_author.username,
              template: topic.journal_author.avatar_template,
              name: topic.journal_author.name
            }), (0, _node.dateNode)(attrs.lastPostAt)])])));
            contents.push((0, _virtualDom.h)("li", [(0, _node.numberNode)(topic.entry_count), (0, _virtualDom.h)("h4", I18n.t(`entry_lowercase`, {
              count: topic.entry_count
            }))]));
            contents.push((0, _virtualDom.h)("li", [(0, _node.numberNode)(topic.comment_count), (0, _virtualDom.h)("h4", I18n.t(`comment_lowercase`, {
              count: topic.comment_count
            }))]));
            contents.push((0, _virtualDom.h)("li.secondary", [(0, _node.numberNode)(attrs.topicViews, {
              className: attrs.topicViewsHeat
            }), (0, _virtualDom.h)("h4", I18n.t("views_lowercase", {
              count: attrs.topicViews
            }))]));
            if (attrs.topicLikeCount) {
              contents.push((0, _virtualDom.h)("li.secondary", [(0, _node.numberNode)(attrs.topicLikeCount), (0, _virtualDom.h)("h4", I18n.t("likes_lowercase", {
                count: attrs.topicLikeCount
              }))]));
            }
            if (attrs.topicLinkLength > 0) {
              contents.push((0, _virtualDom.h)("li.secondary", [(0, _node.numberNode)(attrs.topicLinkLength), (0, _virtualDom.h)("h4", I18n.t("links_lowercase", {
                count: attrs.topicLinkLength
              }))]));
            }
            if (state.collapsed && attrs.topicPostsCount > 2 && attrs.participants.length > 0) {
              const participants = renderParticipants.call(this, attrs.userFilters, attrs.participants.slice(0, 3));
              contents.push((0, _virtualDom.h)("li.avatars", participants));
            }
            const nav = (0, _virtualDom.h)("nav.buttons", this.attach("button", {
              title: "topic.toggle_information",
              icon: state.collapsed ? "chevron-down" : "chevron-up",
              action: "toggleMap",
              className: "btn"
            }));
            return [nav, (0, _virtualDom.h)("ul.clearfix", contents)];
          }
        });
      });
    }
  };
});